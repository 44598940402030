<header *ngIf="!checkOutVisibility" [ngClass]="{'position-fixed': isHomePage}">
  <div class="header-outer">
    <div class="vendorDetails">
      <div class="pnlLogo">
        <a class="lnkLogo normalLayout" [routerLink]="''" *ngIf="companyDetails">
          <img class="center-position " [src]="companyDetails?.storeLogo ?companyDetails?.storeLogo:defaultPath "
            onError="this.src = 'assets/images/defaultLogo.png'" title="{{companyDetails?.storeName}}" alt="{{companyDetails?.storeName}}">
        </a>
      </div>
    </div>
    <div class="menuLinks">
      <div class="menuLinksMainPanel">
        <div class="categoryPnl">
          <p class="categoryLbl">Categories</p>
          <div class="hoverDiv">
            <li *ngFor="let category of categoryList">
              <div id="itrCat-0">
                <a attr.categoryid={{category?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{category?.categoryURL}}">
                  {{category?.categoryName}}
                </a>
                <ul *ngIf="category?.categoryModels != undefined" class="itrCat hide">
                  <li *ngFor="let subCat of category?.categoryModels">
                    <div id="itrCat-1">
                      <a attr.categoryid={{subCat?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{subCat?.categoryURL}}"
                        style="position:relative">
                        {{subCat?.categoryName}}
                      </a>
                      <ul *ngIf="subCat?.categoryModels != undefined" class="secCat">
                        <li *ngFor="let secLevel of subCat?.categoryModels" class="secLevel">
                          <div id="itrCat-2">
                            <a attr.categoryid={{secLevel?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{secLevel?.categoryURL}}">
                              {{secLevel?.categoryName}}
                            </a>
                            <ul *ngIf="secLevel?.categoryModels != undefined" class="thirdCat">
                              <li *ngFor="let thirdLevel of secLevel?.categoryModels" class="thirdLevel">
                                <div id="itrCat-3">
                                  <a attr.categoryid={{thirdLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                    routerLink="collections/{{thirdLevel?.categoryURL}}">
                                    {{thirdLevel?.categoryName}}
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </div>
        </div>
        <div class="collectionPnl">
          <p class="collectionLbl">Collections</p>
          <div class="hoverDiv">
            <ul class="hotCollHdr">
              <li *ngFor="let collection of collectionArray">
                <a routerLink="/collections/{{collection?.collectionURL}}">{{collection?.collectionName}}</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="showsPanel">
          <p class="showsLnk">Shows</p>
        </div> -->
        <div class="linksMenu">
          <a [href]="aboutUsLink" *ngIf="aboutUsPresence">About Us</a>
          <a href="">Sale</a>
          <a href="">Blog</a>
        </div>
      </div>
    </div>
    <div class="loggedUserDtls">
      <div class="loggedUserOuter" *ngIf="!searchExpanded">
        <a class="cartLnk spriteImage" *ngIf="allowCart" routerLink="shopping-cart" routerLinkActive="active"></a>
        <div *ngIf="!loggedIn" class="loginPnlHdr">
          <a class="lnk_login spriteImage"></a>
          <div class="trackOrderLoginDtl">
            <a (click)="goToLogin()">Sign In</a>
            <a (click)="goToRegistration()">Register</a>
            <a [routerLink]="'contact-us'">Contact us</a>
          </div>
        </div>
        <div *ngIf="loggedIn" id="pnlLoginDtls" class="displayLoginDtls loginPnlHdr">
          <a class="lnk_logout spriteImage"></a>
          <div class="trackOrderLoginDtl">
            <p id="lblUserName" class="lblUserName" [innerHTML]="userState.userName !=null ? 'Hi,' + userState.userName + '!!' : 'Hi,' + userState.fName + '!!'"></p>
            <a class="pofile-icon" routerLink="my-dashboard/profile" routerLinkActive="active">Profile</a>
            <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/orders" routerLinkActive="active">Orders</a>
            <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/transactions" routerLinkActive="active">Transaction Logs</a>
            <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/change-password" routerLinkActive="active">Change
              Password</a>
            <a *ngIf="allowWishlist" routerLink="my-dashboard/wishlist" routerLinkActive="active">Wish list</a>
            <a class="signout-icon" class="signInDtl" (click)="logOut()">Sign Out</a>
          </div>
        </div>
        <a class="lnkGo spriteImage" (click)="searchExpand()"></a>
        <a class="wishHdrLnk spriteImage" *ngIf="allowWishlist" routerLink="my-dashboard/wishlist" routerLinkActive="active"></a>
      </div>
      <!-- [ngClass]="{'search-expanded': searchExpanded}" -->
      <div class="app-search" *ngIf="searchExpanded">
        <app-search (inputOutOfFocus)="searchOriginal()"></app-search>
      </div>
    </div>
  </div>
</header>