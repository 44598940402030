import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { OnlineStoreModel } from '../../../common/models/online-store-model';
import { ContactService } from '../../../services/contact.service';
import { SelectItemModel } from '../../../common/models/select-item.model';
import { ContactQueryModel } from '../../../common/models/contact-us-query-model';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LoaderService } from '../../../services/loader.service';
import { HomePageService } from '../../../services/home-page.service';
import { Location } from '@angular/common'
import { HttpService } from 'src/app/services/http.service';
import * as FormData from 'form-data';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  vendorDetails: OnlineStoreModel
  googleMapUrl: string
  subjectList: Array<SelectItemModel> = new Array<SelectItemModel>();
  selectedFile: File;
  formData = new FormData();
  contactForm: FormGroup
  _homePageSubscription
  errorFlag : boolean = true;  
  content:string

  constructor(
    private contactService: ContactService,
    public formBuilder: FormBuilder,
    public dialogService: LsDialogService<OkayMessageComponent>,
    private loaderService: LoaderService,
    private homePageService: HomePageService,
    public location: Location,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.email, Validators.required]],
      phoneNum: ["", Validators.required],
      message: ["", Validators.required],
      queryOption: [-1, Validators.required]
    })
    this._homePageSubscription = this.homePageService.quicLinksObservable.subscribe((links) => {
      let quickLinks = links
      quickLinks.forEach( item =>{
        if(this.location.path() === ("/"+item.pageURL)){
          let cdnPath = item.linkPath;
          this.errorFlag = false;
          if(cdnPath!= undefined && cdnPath!=""){
            this.httpService.getCDNContent(cdnPath, (data) => {
              if (data != undefined && data != "")
                this.content = data;
            });
          }
        }
      })
      // if(this.errorFlag){
      //   this.img404Src = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400
      // }
    })
    this.vendorDetails = Constants.VENDOR_DETAILS;
    this.fetchContactMap();
    this.populateComboData();

  }

  populateComboData() {
    let selectItem: SelectItemModel = new SelectItemModel();
    selectItem.SelectItem(-1, '---Select a Subject---');
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(1, 'Order Related');
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(2, 'Shipment');
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(3, 'My Account')
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(4, 'Payment Related')
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(5, 'Report An Error')
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(6, 'Others')
    this.subjectList.push(selectItem);
  }

  fetchContactMap() {
    let data = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.generalSettingsDTO
    if (data) {
      if (data.addressGoogleMapURL != undefined) {
        this.googleMapUrl = data.addressGoogleMapURL;
      }
    } else {
      this.contactService.getMap(resp => {
        if (resp.data != undefined && resp.data.addressGoogleMapURL != undefined) {
          this.googleMapUrl = resp.data.addressGoogleMapURL;
        }
      })
    }
  }

  onFileChange(event, fileName, changeMenu) {
    this.selectedFile = event.target.files[0];
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0];
      this.formData = new FormData();
      this.formData.append("file[]", fileToUpload, fileToUpload.name);
      fileName.innerHTML = this.selectedFile.name;
      if (!changeMenu.className.includes('changeLnk')) {
        changeMenu.innerHTML = "Change";
        changeMenu.className = "attchment changeLnk";
      }
    }
  }

  submitContactQuery() {
    let queryModel: ContactQueryModel = new ContactQueryModel();
    let fileName: Array<string> = new Array<string>();
    if (this.selectedFile != undefined) {
      fileName.push(this.selectedFile.name);
    }
    this.showLoader();
    queryModel.name = this.contactForm.value.name;
    queryModel.email = this.contactForm.value.email;
    queryModel.mobileNo = this.contactForm.value.phoneNum;
    queryModel.desc = this.contactForm.value.message;
    queryModel.queryStatus = this.contactForm.value.queryOption;
    queryModel.attachments = fileName;
    queryModel.queryType = 2
    this.formData.append("file", this.selectedFile);
    this.formData.append("data", JSON.stringify(queryModel));
    this.contactService.sendContactQuery(this.formData, resp => {
      this.hideLoader();
      this.contactForm.reset();
      this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
      })
    })

  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  private showLoader() {
    this.loaderService.show();
  }

  private hideLoader() {
    this.loaderService.hide();
  }

}
