import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationStart } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { UserService } from '../../../services/user.service';
import { CategoryService } from '../../../services/category.service';
import { LoginService } from '../../../services/login.service';
import { HomePageService } from '../../../services/home-page.service';
import { SeoService } from '../../../services/seo.service';
import { SearchComponent } from '../../components/search/search.component';
import { LoginComponent } from '../../components/login/login.component';
import { CategoryComponent } from '../category/category.component';
import { TrackOrderComponent } from '../../components/track-order/track-order.component';
import { CartComponent } from '../../components/cart/cart.component';
import { DashboardOptionComponent } from '../mobile-dashboard-options/dashboard-options.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { OnlineStoreModel } from '../../../common/models/online-store-model';
import { StaticPageModel } from '../../../common/models/static-page-model';
import { CategoryModel } from '../../../common/models/category-model';
import { UserModel } from '../../../common/models/user-model';
import { CurrencyModel } from '../../../common/models/currency-model';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { YesNoPopupComponent } from '../../commonComponent/yes-no-popup/yes-no-popup.component';
import { Location } from '@angular/common';
import { CartCountService } from '../../../services/cart-count.service';
import { AuthService } from '../../../services/auth.service';
import { CustomerSurveyService } from '../../../services/customerSurvey.service'
import { CustomerSurveyComponent } from '../../commonComponent/customer-survey/customer-survey.component'
import { AnswerModel } from '../../../common/models/answerModel'
import { SocialSettingsModel } from '../../../common/models/social-settings-model';
import { CollectionModel } from '../../../common/models/collectionModel';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { LoginRequestModel } from '../../../common/models/loginRequestModel';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  companyDetails: OnlineStoreModel
  quickLinks: Array<StaticPageModel>
  categoryList: Array<CategoryModel>
  aboutUsPresence: boolean
  exhibitionPresence: boolean
  contactUsPresence: boolean
  aboutUsLink: string
  exhibitionLnk: string
  contactLnk: string
  data;//data of dialog
  dataList;
  value;
  loggedIn: boolean = false
  guestLoggedIn: boolean = false
  userState: UserModel;
  currencyList: Array<CurrencyModel>
  selectedCurrencyIndex: number;
  defaultPath = TemplateConstants.defaultImgPath;
  defaultLogo = "assets/images/defaultLogo.png";
  src
  checkOutVisibility
  formId: number
  @Output('currencyMapEvent') currencyMapEvent: EventEmitter<any> = new EventEmitter<any>()
  @Output('categoryListEvent') categoryListEvent: EventEmitter<any> = new EventEmitter<any>()
  _cartCountSubscription;
  cartCount: number = 0;
  _userStateSubscription;
  socialSettings: SocialSettingsModel;
  fbWidgetSrc: string;
  currType: string = "INR"
  allowCart: boolean;
  allowWishlist: boolean;
  collectionArray: Array<CollectionModel> = [];
  _routeSubscription;
  isHomePage: boolean;
  searchExpanded: boolean;
  currPath: string = ''
  loginRequestModel: LoginRequestModel = new LoginRequestModel();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public categoryService: CategoryService,
    public OkayMessageService: LsDialogService<OkayMessageComponent>,
    public dialogService: LsDialogService<LoginComponent>,
    public cartDialog: LsDialogService<CartComponent>,
    public YesNoDialogService: LsDialogService<YesNoPopupComponent>,
    private userService: UserService,
    private loginService: LoginService,
    private homePageService: HomePageService,
    public location: Location,
    private cartCountService: CartCountService,
    private seoService: SeoService,
    private authService: AuthService,
    public customerSurveyDialogService: LsDialogService<CustomerSurveyComponent>,
    public customerSurveyService: CustomerSurveyService
  ) { }

  ngOnInit() {
    this._routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((data: NavigationStart) => {
      this.currPath = data.url;
      if (data.url == '/' || data.url == '/' + Constants.WAR_NAME) {
        this.isHomePage = true;
      } else {
        this.isHomePage = false;
      }
    });
    this.checkOutVisibility = this.location.path().includes('/checkout');
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('currencyId') != null || sessionStorage.getItem('currencyId') != undefined) {
        this.selectedCurrencyIndex = parseInt(sessionStorage.getItem('currencyId')) - 1;
      } else {
        this.selectedCurrencyIndex = 0;
      }
      if (localStorage.getItem('currentUser') !== null) {
        this.userState = JSON.parse(localStorage.getItem('currentUser'));
        this.userService.update(this.userState);
      }
      if (localStorage.getItem('sessionTimedOut') == "true") {
        localStorage.removeItem('sessionTimedOut');
        localStorage.removeItem('localCart');
        localStorage.removeItem('wishlist');
        localStorage.removeItem('currentUser');
        this.OkayMessageService.open(OkayMessageComponent, null, 'Session has been timed out. Please login again!!').subscribe(response => {
        })
      }
    }
    this.seoService.setMetaData(Constants.SEO_SETTINGS.metaTitle, Constants.SEO_SETTINGS.metaKeyword, Constants.SEO_SETTINGS.metaDesc);
    this.getCartCount();
    this.companyDetails = Constants.VENDOR_DETAILS;
    if (this.companyDetails.storeLogo == null || this.companyDetails.storeLogo == "") {
      this.companyDetails.storeLogo = this.defaultPath;
    }
    this.getSocialLinks();
    this.getQuickLinks();
    this._userStateSubscription = this.userService.userStateModel.subscribe((userModel) => {
      this.userState = userModel;
      if (Object.keys(userModel).length != 0) {
        this.loggedIn = true;
        if (userModel.userType == Constants.UserType.guest) {
          this.guestLoggedIn = true
        }
      } else {
        this.loggedIn = false;
      }
    });
    this.getCollections();
    this.getCategories();
    this.fetchCurrencies();
    if (Constants.CART_SETTINGS != undefined) {
      if (Constants.CART_SETTINGS.allowCart != undefined) {
        if (Constants.CART_SETTINGS.allowCart == 1)
          this.allowCart = true;
        else if (Constants.CART_SETTINGS.allowCart == 0)
          this.allowCart = false;
      }
      if (Constants.CART_SETTINGS.allowWishList != undefined) {
        if (Constants.CART_SETTINGS.allowWishList == 1)
          this.allowWishlist = true;
        else if (Constants.CART_SETTINGS.allowWishList == 0)
          this.allowWishlist = false;
      }
    }
  }

  ngOnDestroy() {
    if(this._routeSubscription){
      this._routeSubscription.unsubscribe();
    }
    if (this._userStateSubscription) {
      this._userStateSubscription.unsubscribe();
    }
    if (this._cartCountSubscription) {
      this._cartCountSubscription.unsubscribe();
    }
  }

  goToLogin() {
    if (this.currPath != '') {
      this.loginRequestModel.moveTo = this.currPath;
      this.loginService.setLoginRequestModel(this.loginRequestModel);
    }
    this.router.navigate(['login']);
  }

  goToRegistration() {
    if (this.currPath != '') {
      this.loginRequestModel.moveTo = this.currPath;
      this.loginService.setLoginRequestModel(this.loginRequestModel);
    }
    this.router.navigate(['login'], { queryParams: { action: 'register' } });
  }

  getCartCount() {     if(!isPlatformBrowser(this.platformId)) {       return     }
    this._cartCountSubscription = this.cartCountService.cart.subscribe((cart) => {
      let currCart = cart;
      if (currCart && JSON.stringify(currCart) != '{}') {
        if (currCart.cartProductModels && currCart.cartProductModels.length > 0) {
          /**
           * check for offer product
           */
          let count = 0;
          currCart.cartProductModels.forEach((ele) => {
            if (!ele.offer) {
              count++;
            }
          });
          this.cartCount = count;
        } else {
          this.cartCount = 0;
        }
      } else {
        if (JSON.parse(localStorage.getItem('localCart'))) {
          let localCart = JSON.parse(localStorage.getItem('localCart'));
          if (localCart.cartProductModels && localCart.cartProductModels.length > 0) {
            /**
           * check for offer product
           */
            let count = 0;
            localCart.cartProductModels.forEach((ele) => {
              if (!ele.offer) {
                count++;
              }
            });
            this.cartCount = count;
          } else {
            this.cartCount = 0;
          }
        } else {
          this.cartCount = 0;
        }
      }
    });
  }

  searchExpand() {
    this.searchExpanded = true;
  }

  searchOriginal() {
    this.searchExpanded = false;
  }

  getQuickLinks() {
    this.homePageService.getQuickLinks(resp => {
      if (resp != undefined) {
        Constants.QUICK_LINKS = resp.data;
        this.homePageService.quicLinksBehaviour.next(resp.data)
        this.quickLinks = Constants.QUICK_LINKS;
        if (this.quickLinks && this.quickLinks.length > 0)
          this.quickLinks.forEach(model => {
            if (model.pageURL.includes('about-us')) {
              this.aboutUsPresence = true;
              this.aboutUsLink = model.pageURL;
            } else if (model.linkName.includes('Exhibition')) {
              this.exhibitionPresence = true;
              this.exhibitionLnk = model.pageURL;
            } else if (model.pageURL.includes('contact-us')) {
              this.contactUsPresence = true;
              this.contactLnk = model.pageURL;
            }
          })
      }
    })
  }

  getCollections() {
    var isDetailPage = false, fetchProducts = true, categoryId;
    this.homePageService.getHomePageCollectionIn(isDetailPage, fetchProducts, categoryId, (data) => {
      if (data != null && !data.error) {
        if (data.data != null || data.data != undefined) {
          let collectionArray = data.data;
          if (collectionArray && collectionArray.length > 0) {
            for (let index = 0; index < collectionArray.length; index++) {
              var ele = collectionArray[index];
              // if (ele.collectionType == Constants.DEFAULT_COLLECTION_TYPE.newArrivals || ele.collectionType == Constants.DEFAULT_COLLECTION_TYPE.bestSellers) {
              this.collectionArray.push(ele);
              // }
            }
          }
          this.homePageService.collectionsBehaviour.next(this.collectionArray);
        }
      }
    });
  }

  showCategories() {
    Constants.MENU_BAR_NAV.toggle();
  }

  showCatChildren(subCatFirst, mobCategory) {
    if (mobCategory.className.includes("mob-categories")) {
      subCatFirst.className = "itrCat itrCat-mob"
    }
  }

  navigateToHome() {
    this.router.navigate(['']);
  }

  fetchCurrencies() {
    this.homePageService.getCurrencyDetails(resp => {
      if (!resp.error && resp.data && resp.data.length > 1) {
        if (isPlatformBrowser(this.platformId)) {
          this.currencyList = resp.data;
          let currMap: Array<{ 'id': any, 'code': any }> = []
          this.currencyList.forEach(ele => {
            currMap.push({
              id: ele.currencyId,
              code: ele.currencyCode
            })
          })
          this.currencyMapEvent.emit(currMap)
          sessionStorage.setItem('currencyMap', JSON.stringify(currMap));
        }
      }
    });
  }


  logOutFunc() {
    var maintainLocalCart = false;
    this.loginService.logOut(maintainLocalCart, resp => {
      if (resp != null && !resp.error) {
        this.dialogService.open(OkayMessageComponent, {}, "You have successfully Logged-out!").subscribe(response => {
          if (isPlatformBrowser(this.platformId)) {
            window.location.href = "";
          }
        })
      }
    });
  }

  logOut() {
    this.openSurvey()
  }

  openSurvey() {
    let data: Array<{ formId: number }> = []
    this.customerSurveyService.getSurveyForm(resp => {
      if (!resp.error) {
        if (resp.data != undefined && resp.data != null && resp.data.formId != undefined && resp.data.formId != null)
          this.formId = resp.data.formId
        data.push({
          formId: this.formId
        })
        this.customerSurveyDialogService.open(CustomerSurveyComponent, {}, data).subscribe((response) => {
          // if (response == 'yes') {
          let answerModel = new AnswerModel()
          answerModel.formId = this.formId
          let isSurveyFormFilled = false
          this.cartCountService.surveyFormFillObservable.subscribe((flag) => {
            isSurveyFormFilled = flag
          })
          if (!isSurveyFormFilled) {
            this.customerSurveyService.submitQuestionsForSurvey(answerModel, (resp) => {
              if (!resp.error)
                this.logOutFunc()
            })
          } else {
            this.logOutFunc()
          }
          // }
        })
      } else {
        this.logOutFunc()
      }
    })
  }

  updateCurrency(selectedCurrencyIndex: number, currencyOption) {
    this.selectedCurrencyIndex = selectedCurrencyIndex;
    let currencyId = this.currencyList[this.selectedCurrencyIndex].currencyId;
    currencyOption.className = "currency-option";
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('currencyId', currencyId + "");
      window.location.reload();
    }

  }

  showCurrencyList(currencyOption) {
    if (currencyOption.className.includes('currencyShow')) {
      currencyOption.className = "currency-option ";
    } else {
      currencyOption.className = "currency-option currencyShow";
    }
  }

  // onOpen() {
  //   this.dialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
  //   });
  // }

  // openLoginDialog() {
  //   if (localStorage.getItem('currentUser') !== null) {
  //     this.dialogService.open(DashboardOptionComponent, {}, null).subscribe(response => { });
  //   } else {
  //     this.dialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
  //       // this.cartAppendHandler()
  //     });
  //   }
  // }

  // cartAppendHandler() {
  //   if (localStorage.getItem('currentUser') != null && localStorage.getItem('currentUser') != ""
  //     && localStorage.getItem('localCart') != null && localStorage.getItem('localCart') != ""
  //   ) {
  //     this.YesNoDialogService.open(YesNoPopupComponent, {}, "Do you want to append to previous cart?").subscribe(response => {
  //       let flag: boolean = false
  //       if (response == "yes") {
  //         flag = true
  //       }
  //       this.homePageService.appendToCart(flag, (data) => {
  //         localStorage.removeItem('localCart')
  //         this.homePageService.getCartCount((data) => {
  //           if (data != undefined && data.data != undefined)
  //             this.cartCountService.cartBehaviour.next(data.data)
  //         })
  //       })

  //     })
  //   }
  //   if (localStorage.getItem('currentUser') != null && localStorage.getItem('currentUser') != ""
  //     && localStorage.getItem('localCart') == null
  //   ) {
  //     this.homePageService.getCartCount((data) => {
  //       if (data != undefined && data.data != undefined)
  //         this.cartCountService.cartBehaviour.next(data.data)
  //     })
  //   }
  // }


  showTrackPopOver() {
    this.dialogService.open(TrackOrderComponent, { panelClass: 'panelClass' }, null).subscribe(response => { });
  }

  getCategories() {
    this.categoryService.getCategories(Constants.GET_CATEGORY_MODE.ALL, resp => {
      if (resp.data != undefined) {
        this.categoryList = resp.data;
        Constants.CATEGORY_MAP = resp.data;
        let childOnlyCats: Array<CategoryModel> = [], parentOnlyCats: Array<CategoryModel> = [];
        if (this.categoryList.length > 0) {
          this.categoryList.forEach((ele) => {
            let parentCat = JSON.parse(JSON.stringify(ele));
            parentCat.categoryModels = undefined;
            parentOnlyCats.push(parentCat);
            if (ele.categoryModels && ele.categoryModels.length > 0) {
              let childCats = ele.categoryModels;
              childCats.forEach((childEle) => {
                childOnlyCats.push(childEle);
              })
            }
          })
        }
        this.homePageService.allCategoriesBehaviour.next(this.categoryList);
        this.homePageService.childCategoriesBehaviour.next(childOnlyCats);
        this.homePageService.parentCategoriesBehaviour.next(parentOnlyCats);
      } else {
        this.categoryList = this.setDummyCategory();
      }
      this.categoryListEvent.emit(this.categoryList)
    })
  }

  uploadDefaultLogoImage($event) {
    $event.target.setAttribute('src', "assets/images/defaultLogo.png");
  }

  openCart() {
    if (window.innerWidth < 1030) {
      this.dialogService.open(CartComponent, { width: '80%' }, null).subscribe(response => {
      });
    } else {
      this.dialogService.open(CartComponent, {}, null).subscribe(response => {
      });
    }
  }

  setDummyCategory() {
    let categoryArray: Array<CategoryModel> = new Array<CategoryModel>();
    for (let i = 0; i < 2; i++) {
      let model: CategoryModel = new CategoryModel();
      let catArray: Array<CategoryModel> = new Array<CategoryModel>();
      model.categoryId = i + "";
      model.categoryURL = "shop" + i;
      model.categoryName = "Shop" + i;
      for (let j = 0; j < 4; j++) {
        let categoryModel: CategoryModel = new CategoryModel();
        let subCatArray: Array<CategoryModel> = new Array<CategoryModel>();
        categoryModel.categoryId = i + "" + j;
        categoryModel.categoryName = "Category" + j;
        categoryModel.categoryURL = "shop" + i + "/category" + j;
        for (let k = 0; k < 3; k++) {
          let subCategory: CategoryModel = new CategoryModel();
          subCategory.categoryId = i + "" + j + "" + k;
          subCategory.categoryName = "SubCategory" + k;
          subCategory.categoryURL = "shop" + i + "/category" + j + "/subCatgeory" + k;
          subCatArray.push(subCategory);
        }
        categoryModel.categoryModels = subCatArray;
        catArray.push(categoryModel);
      }
      model.categoryModels = catArray;
      categoryArray.push(model);
    }
    return categoryArray;
  }

  getSocialLinks() {
    this.socialSettings = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO;
    if (this.socialSettings) {
      if (this.socialSettings.fbClientKey != "" && this.socialSettings.fbClientSecret != "") {
        this.fbWidgetSrc = "https://www.facebook.com/plugins/likebox.php?href=https://www.facebook.com/" + this.socialSettings.fbClientKey
          + "&width=260&height=280&colorscheme=light&show_faces=false&header=true&hide_cover=true&stream=true&show_border=true&appId=" + this.socialSettings.fbClientSecret;
      }
    } else {
      this.homePageService.getStoreSettings("social", resp => {
        this.socialSettings = resp.data;
        if (resp.data.fbClientKey != "" && resp.data.fbClientSecret != "") {
          this.fbWidgetSrc = "https://www.facebook.com/plugins/likebox.php?href=https://www.facebook.com/" + resp.data.fbClientKey + "&width=260&height=280&colorscheme=light&show_faces=false&header=true&hide_cover=true&stream=true&show_border=true&appId=" + resp.data.fbClientSecret;
        }
      })
    }
  }

  goToProductDetailPage(product) {
    let url: string = product.productURLText != undefined ? product.productURLText : '';
    let target = 'details/' + url + '?t=' + product.titleId;
    return target;
  }

  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

  navigateToCart() {
    this.router.navigate(['shopping-cart']);
  }

}
