<div *ngIf="prodData != undefined" class="tileWrapper" [class.showHoverDiv]="showVariants" [class.on-product-tile]="onProductTile">
  <div class="imageWrapper">
    <!-- <div class="addWishPanel fa fa-heart-o" ></div> -->
    <a [routerLink]="'/'+target" [queryParams]="queryParams" style="height: calc(100% - 20px);">
      <img [src]="(prodData?.imageUrl && prodData?.images && prodData?.images[0])?prodData.imageUrl+'m/'+prodData?.images[0]:defaultPath "
        class="img-responsive center-position" alt="" (error)="imgErrorHandler($event)">
    </a>
    <!-- <div class="offer" *ngIf="data?.discountPercent && data?.discountPercent!=0">
      <p class="discount">{{data?.discountPercent}}% &nbsp;off</p>
    </div> -->
  </div>
  <div class="detailDiv">
    <div class="productName hide">
      <p> {{productName}}</p>
    </div>
    <div class="ratingDiv" *ngIf="ratingValue > 0">
      <ls-rating [editable]=false name="ratingVal" [(ngModel)]="ratingValue" ></ls-rating>
    </div>
    <div class="priceDiv" [ngClass]="{'opaque': isListView}">
      <p class="productMrp" *ngIf="prodData?.mrp > prodData?.salePrice && prodData?.showSaving == 1">
        {{prodData?.mrp | currency:currType:'symbol': priceRoundOffInteger}}
      </p>
      <p class="productSalePrice">{{prodData?.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
      <div class="wishlistPnl" (click)="addToWishList()"></div>
    </div>
    <!-- <div class="buyNowPnl" (click)="addToCartProduct()" *ngIf="(prodData?.outOfStock == false && allowCart || (prodData?.outOfStock == true && allowCart && prodData?.acceptOrderOnOutOfStock == 1)) && (!prodData?.releaseDate || (prodData?.releaseDate && prodData?.releaseDate < todaysDate))">
      <a>Buy Now</a>
    </div> -->
  </div>
  <div class="hoverWrapper">
    <a class="moreDetails" [routerLink]="'/'+target" [queryParams]="queryParams">
      More Details
    </a>
  </div>
  <!-- <div class="hoverDiv">
  <div class="pnlFilterItrOuter">
    <ul class="filterItr">
      <div class="filterItrLi" *ngFor="let variantType of variantTypes; let variantTypeIdx = index;">
        <div class="pnlMandtryFieldInner">
          <p class="variantDesc">{{variantType.variantHeading}}</p>
          <ul class="variantItr">
            <li class="variantItrLi" [class.filterSelected]="(optionIdx == selectedFilterIdx)||(optionIdx == 0 && defaultClass == true)"
              *ngFor="let variant of variantType.options; let optionIdx = index;" (click)="setProductVariant($event, variant.mappedUniqueIds, optionIdx)">
              <p class="filter">{{variant.option}}</p>
            </li>
          </ul>
        </div>
      </div>
    </ul>
  </div>
</div> -->
</div>