import { Component, OnInit } from '@angular/core'
import { HomePageService } from '../../../services/home-page.service';
import { BrandModel } from '../../../common/models/brandModel';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  brands: Array<BrandModel> = []
  constructor(    
    private homePageService: HomePageService
    ) { }

  ngOnInit() {
    this.homePageService.fetchAllBrands( data => {
      if(data != undefined && data.data != undefined){
        this.brands = data.data;
        console.log("brands ",this.brands);
      }
    })
  }

}
