import { Component, OnInit } from '@angular/core';

import { CustomerSurveyComponent } from '../../commonComponent/customer-survey/customer-survey.component'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service'
import { CartCountService } from '../../../services/cart-count.service'
import { CustomerSurveyService } from '../../../services/customerSurvey.service'
import { AnswerModel } from '../../../common/models/answerModel';

@Component({
  selector: 'app-order-cancelled',
  templateUrl: './order-cancelled.component.html',
  styleUrls: ['./order-cancelled.component.scss']
})
export class OrderCancelledComponent implements OnInit {

  formId: number

  constructor(
    public customerSurveyDialogService: LsDialogService<CustomerSurveyComponent>,
    private cartCountService: CartCountService,
    private customerSurveyService:CustomerSurveyService
  ) { }

  ngOnInit(){
    let data: Array<{formId: number}> = []    
    let surveyFormRequestData = Object
    setTimeout(() => {
      this.customerSurveyService.getSurveyForm(resp => {
        if (!resp.error) {
          if (resp.data != undefined && resp.data != null && resp.data.formId != undefined && resp.data.formId != null)
            this.formId = resp.data.formId
            data.push({
              formId: this.formId
            })
            this.customerSurveyDialogService.open(CustomerSurveyComponent, {}, data).subscribe((response) => {
              let answerModel = new AnswerModel()
              answerModel.formId = this.formId
              let isSurveyFormFilled = false
              this.cartCountService.surveyFormFillObservable.subscribe((flag) => {
                isSurveyFormFilled = flag
              })
              if (!isSurveyFormFilled) {
                this.customerSurveyService.submitQuestionsForSurvey(answerModel, (resp) => {
              })
            }
          })
        }
      })
    }, 1000);
  }

}
