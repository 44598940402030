import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactQueryModel } from '../../../common/models/contact-us-query-model';
import { ContactService } from '../../../services/contact.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LoaderService } from '../../../services/loader.service';
import * as FormData from 'form-data';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  formData = new FormData();
  partnersForm: FormGroup;
  selectedFile : File;
  

  constructor(
    public formBuilder: FormBuilder,
    private contactService : ContactService,   
    public dialogService: LsDialogService<OkayMessageComponent>,  
    private loaderService : LoaderService 
    
  ) { }

  ngOnInit() {
    this.partnersForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      message: ["", [Validators.required]],
      file: ["", [Validators.required]]
    })
  }

  onFileChange(event){
    this.selectedFile = event.target.files[0];
    if(event.target.files.length > 0){
      let fileToUpload = event.target.files[0];
      this.formData = new FormData();
      this.formData.append("file[]", fileToUpload, fileToUpload.name);
    }
  }
  submitPartners(errorTag) {
    let queryModel : ContactQueryModel = new ContactQueryModel();
    // queryModel.name = this.partnersForm.value.name;
    queryModel.email = this.partnersForm.value.email;
    queryModel.desc = this.partnersForm.value.message;
    this.formData.append("file",this.selectedFile);
    this.formData.append("data", JSON.stringify(queryModel));    
    if(this.selectedFile !=undefined && this.selectedFile.size > 5242880){
      this.dialogService.open(OkayMessageComponent,{},"File size should be less than 5 MB!!").subscribe({

      })
    }else if(queryModel.email==="" || queryModel.email === null){
        errorTag.innerHTML = "Please enter your email ID!";
    }else{
      this.showLoader();
      this.contactService.sendGetForMeRequest(this.formData,queryModel.email,this.partnersForm.value.name,resp=>{
        this.hideLoader();
        this.partnersForm.reset();
        this.dialogService.open( OkayMessageComponent, {},resp.msgList[0]).subscribe(response=>{
        })
      })
    }
    
  }

  private showLoader() {
    this.loaderService.show();
  }

  private hideLoader() {
    this.loaderService.hide();
  }
}
