<div fxLayout="column" class="main-container" [ngClass]="parentClass">
  <div fxLayout="row" class="collectionPart">
    <div class="filterParent" [class.fixed]="navIsFixed" [ngClass]="{'showFilters': _showFilters, 'hideFilters':_showFilters }" *ngIf="!(searchResultsPage || isCollectionUrl)">
      <div class="filterDiv">
        <div class="pnlSubCategoryHeading">
          <p>{{pageTitle}}</p>
        </div>
        <div class="filter-mob" *ngIf="forTemplate">
          <p>Filter By</p>
          <span class="fa fa-lg fa-filter filter-span" (click)="showFilters()"></span>
        </div>
        <!-- <perfect-scrollbar> -->
        <div *ngIf="catMap != undefined" class="pnlCategoryOuter">
          <div class="catName">
            <a *ngFor="let cat of catMap" (click)="navigateToCategoryUrl(cat.catUrl)" [ngClass]="{'selected':cat.catName == pageTitle}">{{cat.catName}}</a>
          </div>
        </div>
        <!-- </perfect-scrollbar> -->
        <div *ngIf="forTemplate">
          <app-filters (onSelectingFilter)="updateCollection($event)" (applyFilterClose)="hideFiltersPanel()"
            [filter-list]="filterList"></app-filters>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="collectionParent">
      <div class="selectedFiltersPanel" *ngIf="FilterModel != undefined && FilterModel.length > 0 && forTemplate">
        <h4 class="showResultsHeading">Showing Results in : </h4>
        <div class="filterTagsPanel">
          <ul class="filterTypeIterator">
            <li *ngFor="let filter of FilterModel; let i=index;">
              <div class="selFilterTypePanel" *ngIf=" filter.filterModelList != undefined && filter.filterModelList.length > 0">
                <p class="filterTypeLabel">{{filter.filterName}} : </p>
                <ul class="filterNameIterator">
                  <ls-tag-container (custom)="remove($event)" [tags]="filter.filterModelList"></ls-tag-container>
                </ul>
              </div>
            </li>
          </ul>
          <div class="clearAllPanel" (click)="clearAllFilters()" *ngIf="FilterModel.length > 0">
            <span class="crossIcon fa fa-close"></span>
            <p class="clearAllLabel">Clear All Filters</p>
          </div>
        </div>
      </div>
      <div class="gridListView" fxShow.gt-xs fxHide.lt-sm *ngIf="!errorPage && forTemplate">
        <div class="fa fa-align-justify list" (click)="listView()"></div>
        <div class="fa fa-th grid" (click)="gridView()"></div>
      </div>
      <div class="title" *ngIf="!errorPage">
        <h2>{{pageTitle}}</h2>
        <span *ngIf="!searchResultsPage" class="fa fa-lg fa-filter filter-span filter-icon" (click)="showFilters()"></span>
        <div class="gridListView" *ngIf="!errorPage">
          <div *ngIf="!list_View" class="fa fa-align-justify list" (click)="listView()"></div>
          <div *ngIf="list_View" class="fa fa-th grid" (click)="gridView()"></div>
        </div>
      </div>
      <div *ngIf="!list_View">
        <div *ngFor="let product of products" class="tile-container" [ngClass]="{'max-width': (searchResultsPage || isCollectionUrl)}">
          <app-product-tile [tile-data]="product" [view]="listViewStr" (addToCart)="addToCartProduct($event)"
            (addToCartWishlist)="addToWishlist($event)"></app-product-tile>
        </div>
      </div>
      <div *ngIf="list_View" class="pnlGrid">
        <div class="pnlProducts">
          <ul>
            <li *ngFor="let product of slider_products; let i = index;" class="tile-container" (click)="selectProd(i)"
              [ngClass]="{'active': selectedProduct == i, 'left1': mod(selectedProduct-1 , slider_products?.length) == i, 'left2': mod(selectedProduct-2 , slider_products?.length) == i, 
          'right1': mod(selectedProduct+1 , slider_products?.length)  == i, 'right2': mod(selectedProduct+2 , slider_products?.length)  == i }">
              <app-product-tile [tile-data]="product" [view]="listViewStr" (addToCart)="addToCartProduct($event)"
                (addToCartWishlist)="addToWishlist($event)"></app-product-tile>
            </li>
          </ul>
        </div>
        <!-- <div *ngFor="let product of products" class="tile-container" [ngClass]="{'max-width': (searchResultsPage || isCollectionUrl)}">
          <app-product-tile [tile-data]="product" [view]="listViewStr" (addToCart)="addToCartProduct($event)"
            (addToCartWishlist)="addToWishlist($event)"></app-product-tile>
        </div> -->
      </div>
      <div class="pnlShowMore" *ngIf="!list_View" [ngClass]="{'show':hasMore}" (click)="showMore()">
        <a class="lnkShowMore">Show More Products</a>
      </div>
      <div class="pnlNoResultOuter" *ngIf="errorPage">
        <div class="pnlNoResultInner">
          <p class="lblNoResult">Couldn't find what you were looking for?</p>
          <p class="noResultLbl2">Just send us detail of the product and we will update you.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="seoContent" *ngIf="content != undefined && content != 'false' && showSeo">
    <div [innerHtml]='content | safeHtml' class="static-content"></div>
  </div>
  <div *ngIf="showError" class="no-results">
    <p>No results found matching your search criteria. Please rephrase your keywords and try again.</p>
  </div>
  <div fxLayoutAlign="center center" class="errorPage" *ngIf="parentClass == 'showErrorPage'">
    <img [src]="errorPageUrl" style="max-width:100%; max-height:100%;">
  </div>
</div>