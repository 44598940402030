import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { SuggestionOptionComponent } from './suggestion-option.component';
import { SearchTagComponent } from './suggestion-tag.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { Router } from '@angular/router';
import { SuggestionComponent } from '../../../LSNG/components/ls-suggestion-field/suggestion/suggestion.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  baseUrl: string;
  parameters: string

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.baseUrl = Constants.BASE_URL + "/getProductSuggestions";
    this.parameters = Constants.SITE_ID + "&storeId=" + Constants.STORE_ID + "&isSuggestion=" + true + "&numRecords=10&isCategorySearch=" + false + "&companyId=" + Constants.VENDOR_DETAILS.companyId;
  }

  optionComponent = SuggestionOptionComponent;
  tag = SearchTagComponent;
  @Output() inputOutOfFocus: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild(SuggestionComponent, {static: false}) suggestionField

  changeUrlSearch(suggestionEleVal, suggestionEle) {
    let data = suggestionEleVal;
    this.suggestionField.textValue = "";
    if (data != undefined && data != "") {
      this.router.navigate(['search'], { queryParams: { k: data } })
    } else if (data == "" || !data){
      this.inputOutOfFocus.emit()
    }
  }

  navigateToDetailPage(event, suggestionEle) {
    let data = event.value;
    if (event.special) {
      // console.log('special case')
      this.suggestionField.textValue = "";
      if (data != undefined && data != "") {
        this.router.navigate(['search'], { queryParams: { k: data } })
      }
    } else {
      this.suggestionField.textValue = "";
      if (data != undefined && data != "") {
        // this.router.navigate(['details/'+ data.split("?t=")[0]], {queryParams: {t : data.split("?t=")[1]}})
        location.href = 'details/' + data
      }
    }
  }

}
