<div fxLayout="column" class="login-component" [ngClass]="{'full-width': !isLoginPage}">
  <div class="outerLoginPnl">
    <div class="headingPnlLogin" fxHide.lt-sm fxShow.gt-xs>
      <p class="headingLbl" [ngClass]="{'registerLbl': !isLoginPage}">{{heading}}</p>
      <span class="close-pop-up fa fa-times-circle-o" (click)="onClick()"></span>
    </div>
    <p class="error-lbl" #errorLabel></p>
    <div class="login-form" *ngIf="isLoginPage" #loginTab>
      <form fxHide.lt-sm fxShow.gt-xs role="form" [formGroup]="loginForm" (ngSubmit)="loginSubmit($event, errorLabel)"
        class="login-desk-view">
        <div fxHide.lt-sm fxShow.gt-xs class="login-email">
          <p class="text-label"> Email ID* </p>
          <input formControlName="email" type="email" tabindex="1" class="txtFieldCls">
        </div>
        <div fxHide.lt-sm fxShow.gt-xs class="login-password">
          <p id="lblPasswordLogin" class="text-label text-pwd">Password*</p>
          <input formControlName="password" type="password" tabindex="2" class="txtFieldCls">
        </div>
        <div class="button-div">
          <a (click)="goToForgotPw()" tabindex="5" id="lnkForgotPwd" class="lnkForgotPwd">Forgot
            password?</a>
          <button class="button lnkLoginBtn" tabindex="3" [disabled]="!loginForm.valid">Login</button>
          <!-- <p tabindex="6" id="signup-tab" class="signup-tab" (click)="changeTab($event,loginTab,signUpForm,signUp,errorLabel)"
            #signUp>Register</p> -->
          <p tabindex="6" id="signup-tab" class="signup-tab" (click)="changeTab(errorLabel)" #signUp>Register</p>
        </div>
        <div class="lowerPnl">
          <div class="chckbx">
            <input formControlName="remember" class="chckbx-selector" type="checkbox" tabindex="4" id="rememberMeChk">
            <label class="chckbx-label" for="rememberMeChk">Remember Me</label>
          </div>
        </div>
      </form>
      <form fxShow.lt-sm fxHide.gt-xs role="form" [formGroup]="loginMobileForm" (ngSubmit)="loginSubmit($event, errorLabel)"
        class="login-mobile-view">
        <input fxShow.lt-sm fxHide.gt-xs formControlName="email" type="email" tabindex="1" class="txtFieldCls" style="margin:0;"
          placeholder="Your Email">
        <input fxShow.lt-sm fxHide.gt-xs formControlName="password" type="password" tabindex="2" class="txtFieldCls"
          placeholder="Password">
        <div>
          <div class="chckbx">
            <input formControlName="remember" class="chckbx-selector" type="checkbox" tabindex="3" id="rememberMeChk1">
            <label class="chckbx-label" for="rememberMeChk1">Remember Me</label>
          </div>
          <a class="lnkForgotPwd" (click)="goToForgotPw()" tabindex="5">Forgot password?</a>
        </div>
        <button class="button lnkLoginBtn" tabindex="4">Login</button>
      </form>
    </div>
    <div class="signup-form" *ngIf="!isLoginPage" #signUpForm>
      <form fxHide.lt-sm fxShow.gt-xs [formGroup]="registerForm" (ngSubmit)="signUpSubmit($event, errorLabel)" class="sign-up-view">
        <div id="signUpPnl" class="signUpPnl">
          <div class="signup-name" fxHide.lt-sm fxShow.gt-xs>
            <p id="lblNameSignup" class="lblFieldClass text-label"> Name* </p>
            <input type="text" tabindex="1" class="txtFieldCls" formControlName="name">
          </div>
          <div fxHide.lt-sm fxShow.gt-xs class="signup-email">
            <p id="lblEmailSignup" class="lblFieldClass text-label">Email ID* </p>
            <input type="email" formControlName="emailSignUp" tabindex="1" id="txtEmailSignup" class="txtFieldCls txtEmailSignup">
          </div>
          <div class="signup-password" fxHide.lt-sm fxShow.gt-xs>
            <p id="lblPasswordSignup" class="lblFieldClass text-label"> Password*</p>
            <input type="password" tabindex="3" formControlName="passwordSignUp" class="txtFieldCls">
            <div id="pnlStrength" class="pnlStrength">
              <span id="spPwdStrength"></span>
            </div>
          </div>
          <div class="form-row" fxHide.lt-sm fxShow.gt-xs>
            <p id="lblPasswordSignup" class="lblFieldClass text-label"> Confirm Password*</p>
            <input type="password" tabindex="4" formControlName="cnfrmPwSignUp" class="txtFieldCls">
            <div id="pnlStrength" class="pnlStrength">
              <span id="spPwdStrength"></span>
            </div>
          </div>
          <div class="form-row" fxHide.lt-sm fxShow.gt-xs>
            <p class="lblFieldClass text-label">Adderss(Line1)</p>
            <input type="text" tabindex="5" formControlName="adderss1SignUp" class="txtFieldCls">
          </div>
          <div class="form-row" fxHide.lt-sm fxShow.gt-xs>
            <p class="lblFieldClass text-label">Address(Line2)</p>
            <input type="text" tabindex="6" formControlName="address2SignUp" class="txtFieldCls">
          </div>
          <div class="form-row" fxHide.lt-sm fxShow.gt-xs>
            <p class="lblFieldClass text-label">State</p>
            <!-- <input type="text" tabindex="7" formControlName="stateSignup" class="txtFieldCls"> -->
            <select class="stateCombo" formControlName="stateSignup">
              <option *ngFor="let item of stateOptions" [value]="item.value">{{item.label}}</option>
            </select>
          </div>
          <div class="form-row" fxHide.lt-sm fxShow.gt-xs>
            <p class="lblFieldClass text-label">City</p>
            <input type="text" tabindex="8" formControlName="citySignup" class="txtFieldCls">
          </div>
          <div class="form-row" fxHide.lt-sm fxShow.gt-xs>
            <p class="lblFieldClass text-label">Phone No</p>
            <div id="mobileField">
              <div id="mobilePrefix">+91</div>
              <input type="text" tabindex="9" formControlName="mobileSignUp" class="txtFieldCls">
            </div>
          </div>
          <div class="form-row" fxHide.lt-sm fxShow.gt-xs>
            <p class="lblFieldClass text-label">Pincode</p>
            <input type="text" tabindex="10" formControlName="pincodeSignup" class="txtFieldCls">
          </div>
        </div>
        <div class="lowerPnl">
          <button class="button lnkSignupBtn" tabindex="4" [disabled]="!registerForm.valid">Register</button>
          <p class="login" (click)="changeTab(errorLabel)">Login</p>
        </div>
        <!-- <p class="login" (click)="changeTab($event,loginTab,signUpForm,signUp,errorLabel)">Click here to login</p> -->
      </form>
      <form fxShow.lt-sm fxHide.gt-xs id="frmSignup" [formGroup]="mobileRegisterForm" (ngSubmit)="signUpSubmit($event, errorLabel)"
        class="sign-up-mob-view">
        <div id="signUpPnl" class="signUpPnl">
          <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName="name" tabindex="1" class="txtFieldCls"
            placeholder="Name*">
          <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName="emailSignUp" tabindex="1" class="txtFieldCls"
            placeholder="Email Address*">
          <input fxShow.lt-sm fxHide.gt-xs type="password" tabindex="3" formControlName="passwordSignUp" id="txtPasswordSignup"
            class="txtFieldCls txtPasswordSignup" placeholder="Password">
          <button class="button lnkSignupBtn" tabindex="4">Register</button>
        </div>
      </form>
    </div>
    <div fxHide.lt-sm fxShow.gt-xs class="loginVia">
      <div *ngIf="fbOauth" id="fbPnl" class="fbPnl fbPnlPresent" tabindex="10" (click)="socialSignIn('facebook', errorLabel)">
        <a id="fbLnk" class="lnkFB">Sign In with Facebook </a>
      </div>
      <div id="gPlusPnl" *ngIf="googleOauth" class="gPlusPnl" tabindex="11" (click)="socialSignIn('google', errorLabel)">
        <a id="gPlusLnk" class="gPlusLnk">Sign In with Google</a>
      </div>
    </div>
  </div>
</div>