<footer *ngIf="!checkOutVisibility" class="defaultWidth">
  <!-- <div fxLayout.lt-sm="column-reverse" fxLayout.gt-xs="column"> -->
  <!-- <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" class="footer-mb defaultWidth" order.lt-sm="2">
      <div *ngIf="showNewsletter" class="newsletter" fxLayout="row" fxFlex="50">
        <input type="email" id="subsEmail" placeholder="Subscribe to our newsletter" class="tfSubscriptionEmail" #inputVal/>
        <p fxShow.gt-xs fxHide.lt-sm class="send-news okLbl" (click)="subscribeNewsletter(error, inputVal)">Send</p>
        <div fxShow.lt-sm fxHide.gt-xs class="mob-send-news">
          <p class="okLbl-mob" (click)="subscribeNewsletter(error, inputVal)">Send</p>
        </div>
        <span #error class="hide errorLbl"></span>
      </div>
      <div *ngIf="socialSettings != undefined" class="social-share" fxLayout="row" fxLayoutAlign.gt-xs="end end" fxFlexAlign.gt-xs="start"
        fxFlexAlign.lt-sm="center" fxFlex="50" style="margin-top:10px;">
        <p fxShow.lt-sm fxHide.gt-xs style="color:#fff; font-family:Myriad-Pro Regular; font-size:15px;padding-top:10px;padding-right: 5px;">Stay With Us</p>
        <a *ngIf="socialSettings.fbPageURL!= ''" href={{socialSettings.fbPageURL}} class="facebook" title="Facebook"><span></span></a>
        <a *ngIf="socialSettings.instagramPageURL!= ''" href={{socialSettings.instagramPageURL}} class="instagram" title="Instagram"><span></span></a>
        <a *ngIf="socialSettings.pinterestPageURL!= ''" href={{socialSettings.pinterestPageURL}} class="pinterest" title="Pinterest"><span></span></a>
        <a *ngIf="socialSettings.googlePageURL!= ''" href={{socialSettings.googlePageURL}} class="gPlus" title="gPlus"><span></span></a>
        <a *ngIf="socialSettings.linkedInPageURL!= ''" href={{socialSettings.linkedInPageURL}} class="linkedin" title="LinkedIn"><span></span></a>
        <a *ngIf="socialSettings.twitterPageURL!= ''" href={{socialSettings.twitterPageURL}} class="twitter" title="Twitter"><span></span></a>
      </div>
    </div> -->
  <!-- <div class="footer-middle-pnl">
      <div fxLayout.gt-xs="row" fxLayout.lt-sm="column-reverse" order.lt-sm="1" class="footer-padding defaultWidth">
        <div class="footer-lower" fxLayout="row" fxFlex.gt-xs="75" fxFlex.lt-sm="100">
          <div fxShow.gt-xs fxHide.lt-sm *ngIf="categoryList != undefined" class="stores" fxLayout="column">
            <p class="heading">SHOP WITH US</p>
            <ul>
              <li *ngFor="let category of categoryList">
                <a routerLink="collections/{{category.categoryURL}}" class="lnkStores">{{category.categoryDisplayName}}</a>
              </li>
            </ul>
          </div>
          <div fxShow.gt-xs fxHide.lt-sm class="account-info" fxLayout="column">
            <p class="heading">INFORMATION</p>
            <div fxLayout="column">
              <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/profile' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Profile</a>
              <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/orders' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Orders</a>
              <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/transactions' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Transaction
                Logs</a>
              <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/change-password' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Change
                Password</a>
              <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/wishlist' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Wishlist</a>
            </div>
          </div>
          <div *ngIf="quickLinks != undefined" class="quicklinks" fxLayout.gt-xs="column" fxLayout.lt-sm="row">
            <p fxShow.gt-xs fxHide.lt-sm class="heading">QUICKLINKS</p>
            <ul>
              <li *ngFor="let quickLink of quickLinks">
                <a *ngIf="quickLink.pageURL.includes('http')" [href]="httpUrl" class="lnkStores" target="_blank">{{quickLink.linkName}}</a>
                <a *ngIf="!quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores">{{quickLink.linkName}}</a>
              </li>
              <li><a fxHide.gt-xs fxShow.lt-sm routerLink="blog" class="lnkStores" style="display:inline-block;"> Blog</a></li>
              <li><a fxHide.gt-xs fxShow.lt-sm routerLink="partners" class="lnkStores" style="display:inline-block; border-right:medium none;padding-left:10px;">Partners
                  in Prosperity</a></li>
            </ul>
          </div>
        </div>
         <div *ngIf="socialSettings != undefined && socialSettings.fbClientKey!='' && socialSettings.fbClientSecret!=''" class="fb-widget"
        fxLayout="row" fxFlex.gt-xs="25" fxFlex.lt-sm="100">
        <iframe [attr.src]='fbWidgetSrc | safeUrl' height="280px" width="100%" allowtransparency="true" frameborder="0" scrolling="yes"></iframe>
      </div>
      </div>
    </div> -->
  <!-- </div> -->
  <div class="footerPnl">
    <div class="pnlFooter">
      <div class="pnlNewsLetter" *ngIf="showNewsletter">
        <p #error class="visibility-hide errorLbl">&nbsp;</p>
        <input type="text" class="tfSubscriptionEmail" [placeholder]="'Subscribe to our newsletter'" (keydown)="subscribeKeyup($event, error, inputVal)"
          #inputVal>
        <span class="sendSpan" (click)="subscribeNewsletter(error, inputVal)">Submit</span>
      </div>
      <div class="infoCont">
        <div class="infoFootLabel">
          <p>INFORMATION</p>
        </div>
        <div class="quickConPanel" *ngIf="quickLinks != undefined">
          <ul id="quickLinkItr">
            <li *ngFor="let quickLink of quickLinks">
              <a *ngIf="quickLink.pageURL.includes('http')" [href]="httpUrl" class="lnkStores" target="_blank">{{quickLink.linkName}}</a>
              <a *ngIf="!quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores">{{quickLink.linkName}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="pnlAccount">
        <div class="pnlAccountLbl">
          <p>MY ACCOUNT</p>
        </div>
        <div class="pnlAccountLinks">
          <div>
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/profile' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Profile</a>
          </div>
          <div>
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/orders' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Order
              History</a>
          </div>
          <div>
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/transactions' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">TransactionLogs</a>
          </div>
          <div>
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/change-password' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Change
              Password</a>
          </div>
          <div>
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/wishlist' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Wishlist</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="innerCopyright">
      <div class="pnl">
        <div class="copyright-txt">
          <p [innerHtml]="copyRTxt"></p>
        </div>
        <div class="power-by">
          <a href="http://www.lsnetx.com" target="_blank" class="copy-right-img"><img src="assets/logo.png" title="LSNetX"
              alt="LSNetX"></a>
        </div>
        <div class="rights">
          <p fxShow.gt-xs fxHide.lt-sm class="power-txt">All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </div>
  <div>
  </div>
</footer>