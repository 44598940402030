import { Component, OnInit, AfterViewInit, ViewChildren, ElementRef, QueryList, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { ProductDetailsService } from '../../../services/product-details.service';
import { GetReviewListService } from '../../../services/get-review-list.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { HomePageService } from '../../../services/home-page.service';
import { SeoService } from '../../../services/seo.service';
import { UserCartDetailDto } from '../../../common/models/userCartDetailDto';
import { ProductModel } from '../../../common/models/productModel';
import { UserModel } from '../../../common/models/user-model';
import { StaticPageModel } from '../../../common/models/static-page-model';
import { GeneralSettingsModel } from '../../../common/models/generalSettingsModel';
import { ReviewAvgModel } from '../../../common/models/reviewAvgModel';
import { SocialSettingsModel } from '../../../common/models/social-settings-model';
import { ProductIdentifier } from '../../../common/models/productIdentifier';
import { ProductReviewComponent } from '../../components/product-review/product-review.component';
import { LoginComponent } from '../../components/login/login.component';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { ProductImgDialogComponent } from './product-img-dialog/product-img-dialog.component';
import { CustomerSurveyComponent } from '../../commonComponent/customer-survey/customer-survey.component';
import { CustomerSurveyService } from '../../../services/customerSurvey.service';
import { CartComponent } from '../../components/cart/cart.component';
import { CartCountService } from '../../../services/cart-count.service';
import { OfferDTO } from '../../../common/models/offerDto';
import { ActionDTO } from '../../../common/models/actionDto';
import { ConditionDTO } from '../../../common/models/conditionDto';
import { OfferItemDTO } from '../../../common/models/offerItemDto';
import { UserService } from '../../../services/user.service';
import { CartService } from '../../../services/cart.service';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { AnswerModel } from '../../../common/models/answerModel';
import { CollectionModel } from '../../../common/models/collectionModel';
import { TemplateConstants } from '../../commonComponent/template-constants';

export class dims {
  height: number
  width: number
}

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  defaultPath = TemplateConstants.defaultImgPath;
  currVariantId
  currType: string = "INR"
  imageUrl: string
  imagesList
  imageThumbnailsList = new Array<string>()
  image
  productName
  mrp
  gst: number
  salePrice
  leftUpdatedValue: number = 0
  leftcheck: number = 0
  giveRating = 0
  getRating = 0
  productQuantity: number = 1
  discount
  discountPercent
  shippingDays: number = Constants.DEFAULT_SHIPPING_DAYS
  featureArray: Array<{ name: string, details: Array<{ key: string, value: string }> }> = []
  variantTypes: Array<{ variantHeading: string, options: Array<{ option: string, mappedUniqueIds: Array<number> }> }> = []
  selectedOptionIdx = 0
  selectedVariantTypeIdx = 0
  selectedVariantId
  currentPageUrl = ''
  socialShareFbUrl = ''
  socialShareGoogleUrl = ''
  socialShareLinkedInUrl = ''
  socialShareTwitterUrl = ''
  pageNum = 1
  numRecords = 28
  ratingPnls = []
  totalReviews = []
  defaultVariant
  selectionArray = []
  defaultSelectedArray = []
  returnLinkPath
  youtubeEmbedLinkUrl
  funFacts = []
  _readAll = false
  _showReadAll = false
  playVideo = false
  productImageHover: boolean = false
  showReturnFlag = false
  isProNotAvailable = false
  buyMode = false
  enquiryMode = false
  buyAndEnquiryMode = false
  filterSelected = false
  isoutOfStock
  showGst
  showSaving
  isFbHash = false
  isGoogleHash = false
  isLinkedInHash = false
  isTwitterHash = false
  youtubeEmbedLink
  funFactsDetailsPage = false
  _hideAllFacts = false
  allowWishlist
  allowCart
  showError: boolean = false
  productDetails: ProductModel
  firstProduct
  ratingAndReviewData: ReviewAvgModel
  generalSettings: GeneralSettingsModel
  socialSettings: SocialSettingsModel
  quickLinks: Array<StaticPageModel>
  titleIdList: Array<number> = new Array<number>()
  variantMap = new Map<number, {
    index: number,
    mapped: Array<number>
  }>();
  currUser: UserModel
  addReviewLbl = "Add Your Review"
  priceTag = "Price"
  quantityTag = "Quantity"
  statusTag = "Status"
  availableTag = "Available"
  notAvailableTag = "Not Available"
  outOfStockTag = "Out of Stock"
  shippingChargesTag = "Shipping Charges"
  codChargesTag = "COD Charges"
  gstChargesTag = "GST Charges"
  handlingChargesTag = "Handling Charges"
  aboutProduct = "About Product"
  buyNowLbl = "Buy it Now"
  addToCartLbl = "Add to Cart"
  freeShippingLbl = "Free Shipping"
  callForPriceTag = "Call For Price"
  lblCustomerReviews = "Customer Reviews"
  rateLbl = "Rating"
  rateNowLabel = "Share your thoughts with other customers"
  rateNowBtnLabel = "Write a product review"
  ratingSummaryLbl = "Rating Summary"
  lblFunfacts = "Fun Facts About"
  lblBamboo = "BAMBOO"
  lblFactsRead = "Click to Read"
  shippingDaysLbl = "Estimated Shipping in:  5-7 days "
  lblSocialHdr = "share it on"
  cartProductMdel = new UserCartDetailDto();
  currUserEmail
  titleId
  variantId
  errorPageUrl = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400 ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400 : TemplateConstants.templateAssetsPath + '/images/errorPages/404_pageNotFound.jpg'
  cartCount: number
  isMobile: boolean = false
  isDesktop: boolean = true
  isTab: boolean = false
  formId: number
  ffRightUpdatedValue: number = 0
  isFunFactMinimized: boolean = false
  availOfferImgUrl = 'assets/images/Avail-offer-img.jpg'
  getSurveyForm = false
  surveyFormData: Array<{ formId: number }> = []
  ffSlideTimer: any;
  surveyCouponDiscount = 15;
  FirstTime: boolean = true;
  isBuy: boolean = false;
  _catSubscription;
  _catSubscription2;
  priceRoundOffInteger: string = '1.2';
  discountRoundOffInteger: string = '1.2';
  _routeSubscription;
  newProd;

  @ViewChildren("featureSnippet") featureSnippet: QueryList<ElementRef>
  @ViewChildren("barRatingPnlsValue") barRatingPnlsValue: QueryList<ElementRef>
  @ViewChildren("barRatingPnlsBar") barRatingPnlsBar: QueryList<ElementRef>
  @ViewChildren("productMainImage") productMainImage
  // @ViewChild("sliderDiv") sliderDiv: ElementRef
  // @ViewChild("ffSliderDiv1") ffSliderDiv1: ElementRef
  // @ViewChild("ffSliderDiv2") ffSliderDiv2: ElementRef
  // @ViewChild("ffSliderDiv3") ffSliderDiv3: ElementRef
  // @ViewChild("ffSliderDiv4") ffSliderDiv4: ElementRef  

  constructor(
    public seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public productDetailsService: ProductDetailsService,
    public homePageService: HomePageService,
    public getReviewListService: GetReviewListService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public addReviewDialogService: LsDialogService<ProductReviewComponent>,
    public loginDialogService: LsDialogService<LoginComponent>,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public cartDialog: LsDialogService<CartComponent>,
    public productImgDialog: LsDialogService<ProductImgDialogComponent>,
    public location: Location,
    private cartCountService: CartCountService,
    public customerSurveyDialogService: LsDialogService<CustomerSurveyComponent>,
    public customerSurveyService: CustomerSurveyService,
    private cartService: CartService
  ) {
    this.currentPageUrl = window.location.href;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(window.navigator.userAgent)) {
        this.isMobile = true
        this.isTab = false
        this.isDesktop = false
      } else if (/Mobi|Tablet|iPad|kindle/i.test(window.navigator.userAgent)) {
        this.isMobile = false
        this.isTab = true
        this.isDesktop = false
      } else {
        this.isMobile = false
        this.isTab = false
        this.isDesktop = true
      }
      this.checkSurveyFormVisibility()
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
      if (Constants.CART_SETTINGS != undefined) {
        if (Constants.CART_SETTINGS.allowCart != undefined) {
          if (Constants.CART_SETTINGS.allowCart == 1)
            this.allowCart = true;
          else if (Constants.CART_SETTINGS.allowCart == 0)
            this.allowCart = false;
        }
        if (Constants.CART_SETTINGS.allowWishList != undefined) {
          if (Constants.CART_SETTINGS.allowWishList == 1)
            this.allowWishlist = true;
          else if (Constants.CART_SETTINGS.allowWishList == 0)
            this.allowWishlist = false;
        }
      }
      this.titleIdList.push(Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("t").toString()));
      this.setFunFacts();
      this.getGeneralSettings();
      this.getSocialSettings();
      var routerNav = false;
      this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
        if (e instanceof NavigationEnd) {
          var newUrl = e.url;
          var newTitleId = Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("t").toString());
          if (newUrl.includes('details/') && this.titleIdList[0] != newTitleId) { //if titleId is not changed, then no new product
            routerNav = true;
            this.currentPageUrl = newUrl;
            this.initializeNewProd();
          }
        }
      });
      if (!routerNav) {
        this.getPorductDetailsUtility();
      }
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        this.currUserEmail = this.currUser.email;
      }
    }
  }

  initializeNewProd() {
    this.titleIdList = [];
    this.currVariantId;
    this.imageUrl;
    this.imagesList;
    this.imageThumbnailsList = new Array<string>();
    this.image;
    this.productName;
    this.mrp;
    this.gst;
    this.salePrice;
    this.leftUpdatedValue = 0;
    this.leftcheck = 0;
    this.giveRating = 0;
    this.getRating = 0;
    this.productQuantity = 1;
    this.discount;
    this.discountPercent;
    this.featureArray = [];
    this.variantTypes = [];
    this.selectedOptionIdx = 0;
    this.selectedVariantTypeIdx = 0;
    this.selectedVariantId;
    this.socialShareFbUrl = '';
    this.socialShareGoogleUrl = '';
    this.socialShareLinkedInUrl = '';
    this.socialShareTwitterUrl = '';
    this.ratingPnls = [];
    this.totalReviews = [];
    this.defaultVariant;
    this.selectionArray = [];
    this.defaultSelectedArray = [];
    this.returnLinkPath;
    this.youtubeEmbedLinkUrl;
    this._readAll = false;
    this._showReadAll = false;
    this.showReturnFlag = false;
    this.isProNotAvailable = false;
    this.buyMode = false;
    this.enquiryMode = false;
    this.buyAndEnquiryMode = false;
    this.filterSelected = false;
    this.isoutOfStock;
    this.getPorductDetailsUtility();
  }

  getPorductDetailsUtility() {
    this.titleIdList.push(Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("t").toString()));
    if (this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum")) {
      this.currVariantId = this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum");
    }
    this.getProductDetails();
    this.setSocialSharePageUrls();
    this.getReviewsList();
  }

  ngAfterViewInit() {
    this.featureSnippet.changes.subscribe(() => {
      if (this.featureSnippet.toArray()[0]) {
        setTimeout(() => {
          if (this.featureSnippet.toArray()[0].nativeElement.getBoundingClientRect().height > 47) {
            this._showReadAll = true;
          } else
            this._showReadAll = false
        }, 0)
      }
    })

    this.barRatingPnlsValue.changes.subscribe(() => {
      this.barRatingPnlsValue.toArray().forEach((element, index) => {
        element.nativeElement.innerHTML = (Math.ceil(this.ratingPnls[index] * 100)) + '%'
      })
    })
    this.barRatingPnlsBar.changes.subscribe(() => {
      this.barRatingPnlsBar.toArray().forEach((element, index) => {
        element.nativeElement.style.height = (this.ratingPnls[index] * 85) + 'px'
      })
    })
  }

  ngOnDestroy() {
    if (this._catSubscription) {
      this._catSubscription.unsubscribe();
    }
    if (this._catSubscription2) {
      this._catSubscription2.unsubscribe();
    }
  }

  getGeneralSettings() {
    this.generalSettings = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.generalSettingsDTO
    if (this.generalSettings) {
      if (this.generalSettings.priceRoundOffInteger == 1) {
        this.priceRoundOffInteger = '1.0'
      } else if (this.generalSettings.priceRoundOffInteger == 0) {
        this.priceRoundOffInteger = '1.2'
      }
      if (this.generalSettings.discountRoundOffInteger == 1) {
        this.discountRoundOffInteger = '1.0'
      } else if (this.generalSettings.discountRoundOffInteger == 0) {
        this.discountRoundOffInteger = '1.2'
      }
      // if (this.generalSettings.showSaving == 1)
      //   this.showSaving = true;
      // else
      //   this.showSaving = false;
      if (this.generalSettings.mrpInclusiveOfTax == 1) {
        this.showGst = false;
      } else if (this.generalSettings.mrpInclusiveOfTax == 0) {
        this.showGst = true;
      }
    } else {
      this.productDetailsService.getGeneralSettings((data) => {
        if (data) {
          this.generalSettings = data.data;
          if (this.generalSettings.priceRoundOffInteger == 1) {
            this.priceRoundOffInteger = '1.0'
          } else if (this.generalSettings.priceRoundOffInteger == 0) {
            this.priceRoundOffInteger = '1.2'
          }
          if (this.generalSettings.discountRoundOffInteger == 1) {
            this.discountRoundOffInteger = '1.0'
          } else if (this.generalSettings.discountRoundOffInteger == 0) {
            this.discountRoundOffInteger = '1.2'
          }
          // if (this.generalSettings.showSaving == 1)
          //   this.showSaving = true;
          // else
          //   this.showSaving = false;
          if (this.generalSettings.mrpInclusiveOfTax == 1) {
            this.showGst = false;
          } else if (this.generalSettings.mrpInclusiveOfTax == 0) {
            this.showGst = true;
          }
        }
      });
    }
  }

  getSocialSettings() {
    this.socialSettings = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO
    if (this.socialSettings) {
      let hash = this.socialSettings.allowSharingOnDtlPage;
      if (hash && hash.length > 0) {
        for (let i = 0; i < hash.length; i++) {
          if (hash.split('#')[i] != "#") {
            switch (hash.split('#')[i]) {
              case '1': this.isFbHash = true; break;
              case '2': this.isGoogleHash = true; break;
              case '3': this.isLinkedInHash = true; break;
              case '4': this.isTwitterHash = true; break;
            }
          }
        }
      }
    } else {
      this.homePageService.getStoreSettings("social", (data) => {
        if (data) {
          this.socialSettings = data.data;
          let hash = this.socialSettings.allowSharingOnDtlPage;
          if (hash && hash.length > 0) {
            for (let i = 0; i < hash.length; i++) {
              if (hash.split('#')[i] != "#") {
                switch (hash.split('#')[i]) {
                  case '1': this.isFbHash = true; break;
                  case '2': this.isGoogleHash = true; break;
                  case '3': this.isLinkedInHash = true; break;
                  case '4': this.isTwitterHash = true; break;
                }
              }
            }
          }
        }
      })
    }
  }

  getReviewsList() {
    if (this.titleIdList && this.titleIdList.length > 0) {
      this.getReviewListService.getReviewList(this.titleIdList[0], this.pageNum, this.numRecords, (data) => {
        if (data != undefined) {
          this.ratingAndReviewData = data.data;
          if (this.ratingAndReviewData) {
            this.ratingPnls = this.ratingAndReviewData.ratingAvgList;
            this.totalReviews = this.ratingAndReviewData.reviewModels;
          }
        }
      })
    }
  }

  setSocialSharePageUrls() {
    this.socialShareFbUrl = "http://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.currentPageUrl);
    this.socialShareGoogleUrl = "http://plus.google.com/share?url=" + encodeURIComponent(this.currentPageUrl);
    this.socialShareLinkedInUrl = "http://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(this.currentPageUrl) + "&source=" + encodeURIComponent(this.currentPageUrl);
    this.socialShareTwitterUrl = "http://twitter.com/intent/tweet?url=" + encodeURIComponent(this.currentPageUrl) + "&via=TWITTER-HANDLE";
  }

  getProductDetails() {
    let getReviews = true
    let offerBookCall = false
    this.productDetailsService.getProductDetails(getReviews, this.titleIdList, 2, offerBookCall, (data) => {
      if (!data.error) {
        if (data != undefined) {
          this.productDetails = data.data[0];
          if (this.productDetails.categoryId) {
            this.newProd = {
              'titleId': this.titleIdList[0],
              'categoryId': this.productDetails.categoryId
            }
          }
          if (this.productDetails.youtubeEmbedLink == undefined || this.productDetails.youtubeEmbedLink == "") {
            this.youtubeEmbedLink = false;
          } else {
            this.youtubeEmbedLink = true;
            this.youtubeEmbedLinkUrl = this.productDetails.youtubeEmbedLink;
          }
          if (this.productDetails.variantList == null) {
            this.productName = this.productDetails.productName;
            this.imageUrl = this.productDetails.imageUrl;
            this.imagesList = this.productDetails.images;
            let i = 0;
            if (this.imagesList != undefined) {
              this.imagesList.forEach(imgSrc => {
                if (imgSrc != undefined) {
                  this.imageThumbnailsList[i++] = this.imageUrl + 's/' + imgSrc;
                }
              });
              this.image = this.imageUrl + 'l/' + this.imagesList[0];
            }
            this.isoutOfStock = this.productDetails.outOfStock;
            this.giveRating = this.productDetails.productRatingAvg;
            this.mrp = this.productDetails.mrp;
            this.salePrice = this.productDetails.salePrice;
            this.discount = this.productDetails.discount;
            this.showSaving = this.productDetails.showSaving
            this.gst = this.productDetails.gst;
            if (this.productDetails.shippingDays > 0) {
              this.shippingDays = this.productDetails.shippingDays + Constants.PRODUCT_SHIPPING_DAYS_ADDITION;
            }
            if (this.productDetails.buyEnquiryMode == Constants.BUY_MODE) {
              this.buyMode = true;
            } else if (this.productDetails.buyEnquiryMode == Constants.ENQUIRY_MODE) {
              this.enquiryMode = true;
            } else if (this.productDetails.buyEnquiryMode == Constants.BUY_AND_ENQUIRY_MODE) {
              this.buyAndEnquiryMode = true;
            }
            let tempArray: Object = this.productDetails.attributes;
            if (tempArray) {
              Object.keys(tempArray).forEach(key => {
                let value = tempArray[key]
                let featArray: Array<{ key: string, value: string }> = []
                Object.keys(value).forEach(element => {
                  featArray.push({
                    key: element,
                    value: value[element]
                  })
                })
                this.featureArray.push({
                  name: key,
                  details: featArray
                })
              });
            }
          } else {
            if (!this.activatedRoute.snapshot.queryParamMap.has("sizeRecNum")) {
              this.firstProduct = this.productDetails.variantList[0];
              this.currVariantId = this.firstProduct.id;
            } else {
              this.productDetails.variantList.forEach(variant => {
                if (variant.id == parseInt(this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum"))) {
                  this.firstProduct = variant;
                }
              })
            }
            this.imageUrl = this.productDetails.imageUrl;
            this.productName = this.productDetails.productName;
            this.imagesList = [];
            this.imageThumbnailsList = [];
            this.imagesList = this.firstProduct.images;
            let i = 0;
            if (this.imagesList != undefined) {
              this.imagesList.forEach(imgSrc => {
                if (imgSrc != undefined) {
                  this.imageThumbnailsList[i++] = this.imageUrl + 's/' + imgSrc;
                }
              });
              this.image = this.imageUrl + 'l/' + this.imagesList[0];
            }
            this.isoutOfStock = this.firstProduct.outOfStock;
            this.giveRating = this.firstProduct.productRatingAvg;
            this.mrp = this.firstProduct.mrp;
            this.salePrice = this.firstProduct.salePrice;
            this.discount = this.firstProduct.discount;
            this.discountPercent = this.firstProduct.discountPercent;
            this.showSaving = this.firstProduct.showSaving
            this.gst = this.firstProduct.gst;
            if (this.productDetails.buyEnquiryMode == Constants.BUY_MODE) {
              this.buyMode = true;
            } else if (this.productDetails.buyEnquiryMode == Constants.ENQUIRY_MODE) {
              this.enquiryMode = true;
            } else if (this.productDetails.buyEnquiryMode == Constants.BUY_AND_ENQUIRY_MODE) {
              this.buyAndEnquiryMode = true;
            }
            this.featureArray = [];
            if (this.firstProduct.attributes) {
              let tempArray: Object = this.firstProduct.attributes;
              Object.keys(tempArray).forEach(key => {
                let value = tempArray[key]
                let featArray: Array<{ key: string, value: string }> = []
                Object.keys(value).forEach(element => {
                  featArray.push({
                    key: element,
                    value: value[element]
                  })
                })
                this.featureArray.push({
                  name: key,
                  details: featArray
                })
              });
            }
            this.getVariants();
          }
          this.setSeoData();
        }
      } else {
        this.showError = true;
      }
    });
  }

  getQuickLinks() {
    this.homePageService.quicLinksObservable.subscribe((quickLinks) => {
      this.quickLinks = quickLinks
      if (this.quickLinks && this.quickLinks.length > 0) {
        this.quickLinks.forEach(link => {
          if (link.linkName == "Return & Refund") {
            this.showReturnFlag = true;
            this.returnLinkPath = link.linkPath;
          }
        })
      }
    })
  }

  setSeoData() {
    if (this.productDetails && this.image) {
      this.seoService.setMetaDataForDetailsPage(this.currType, this.image, this.productDetails, this.productDetails.metaTagTitle, this.productDetails.metaTagKeyword, this.productDetails.metaTagDesc);
    }
  }

  getVariants() {
    if (this.productDetails.variantAttrMap != undefined) {
      let tempVariantArr = this.productDetails.variantAttrMap;
      Object.keys(tempVariantArr).forEach(key => {
        let filters = tempVariantArr[key]
        let filtersArr: Array<{ option: string, mappedUniqueIds: Array<number> }> = []
        Object.keys(filters).forEach(element => {
          let idxArr: Array<number> = []
          filters[element].forEach(idxValue => {
            idxArr.push(idxValue);
          });
          filtersArr.push({
            option: element,
            mappedUniqueIds: idxArr
          })
        })
        this.variantTypes.push({
          variantHeading: key,
          options: filtersArr
        })
      })
      this.setDefaultSelectedVariants();
    }
  }

  setDefaultSelectedVariants() {
    if (this.activatedRoute.snapshot.queryParamMap.has("sizeRecNum")) {
      this.productDetails.variantList.forEach(variant => {
        if (variant.id == parseInt(this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum").toString()))
          this.defaultVariant = variant.variantAttributes;
      })
    } else {
      this.defaultVariant = this.productDetails.variantList[0].variantAttributes;
    }
    let variantAttrMap: Array<{ type: string, option: any }> = []
    Object.keys(this.defaultVariant).forEach(key => {
      variantAttrMap.push({
        type: key,
        option: this.defaultVariant[key]
      })
    })
    for (let type = 0; type < this.variantTypes.length; type++) {
      for (let i = 0; i < variantAttrMap.length; i++) {
        if (this.variantTypes[type].variantHeading == variantAttrMap[i].type) {
          for (let j = 0; j < this.variantTypes[type].options.length; j++) {
            if (this.variantTypes[type].options[j].option == variantAttrMap[i].option) {
              this.selectionArray[type] = j;
            }
          }
        }
      }
    }
    this.defaultSelectedArray = this.selectionArray;
  }

  showZoomImgDialog(imageThumbnailsList, productName, image) {
    if (this.isTab || this.isMobile) {
      let images = {
        imageUrl: this.imageUrl,
        imagesList: this.imagesList,
        imageThumbnailsList: imageThumbnailsList,
        productName: productName,
        selectedImg: image,
        isImages: true,
        isSharingDialog: false
      }
      this.productImgDialog.open(ProductImgDialogComponent, {}, images).subscribe(response => {
      });
    }
  }

  slideLeft() {
    this.leftcheck = (this.imageThumbnailsList.length - 3) * -34.2;
    if (this.leftUpdatedValue > this.leftcheck)
      this.leftUpdatedValue = this.leftUpdatedValue - 34.2;
  }

  slideRight() {
    if (this.leftUpdatedValue < 0)
      this.leftUpdatedValue = this.leftUpdatedValue + 34.2;
  }

  updateImageSrc(thumbnailIndex) {
    this.image = this.imageUrl + 'l/' + this.imagesList[thumbnailIndex];
  }

  showZoomImage(e: TouchEvent) {
    this.productImageHover = true;
  }

  hideZoomImage() {
    this.productImageHover = false;
  }

  goToReturnAndRefund() {
    this.router.navigate(['return-and-refund']);
  }

  moveIt(xOffset: number, yOffset: number): dims {
    let smallerImageDims: dims = {
      height: 450,
      width: 450
    }

    let largerImageDims: dims = {
      height: 560,
      width: 600
    }

    let scale: dims = {
      height: smallerImageDims.height / largerImageDims.height,
      width: smallerImageDims.width / largerImageDims.width
    }

    let position: dims = {
      height: largerImageDims.height / 2 - (yOffset / scale.height),
      width: largerImageDims.width / 2 - (xOffset / scale.width)
    }

    return position
  }

  zoomImgBgPos(event: MouseEvent) {
    if (this.isDesktop) {
      this.productImageHover = true;
      let xPos = event.offsetX;
      let yPos = event.offsetY;
      let backgroundPosition = this.moveIt(xPos, yPos)
      this.productMainImage.toArray()[0].nativeElement.style.backgroundPosition = backgroundPosition.width + "px " + backgroundPosition.height + 'px'
    }
  }

  decreaseProQuantity() {
    if (this.productQuantity > this.productDetails.minOrderQty && this.productQuantity > 1)
      this.productQuantity = this.productQuantity - 1;
  }

  increaseProQuantity() {
    if (this.productQuantity < this.productDetails.maxOrderQty && this.productQuantity > 0)
      this.productQuantity = this.productQuantity + 1;
  }

  readAll() {
    this._readAll = true;
  }

  playProVideo() {
    if (this.playVideo == false)
      this.playVideo = true;
  }

  showProImages() {
    if (this.playVideo == true)
      this.playVideo = false;
  }

  openSharingDialog() {
    let data = {
      fbUrl: this.socialShareFbUrl,
      googleUrl: this.socialShareGoogleUrl,
      linkedInUrl: this.socialShareLinkedInUrl,
      twitterUrl: this.socialShareTwitterUrl,
      isFb: this.isFbHash,
      isGoogle: this.isGoogleHash,
      isLinkedIn: this.isLinkedInHash,
      istwitter: this.isTwitterHash,
      isImages: false,
      isSharingDialog: true
    }
    this.productImgDialog.open(ProductImgDialogComponent, {}, data).subscribe(response => {
    });
  }

  // buyProduct() {
  //   if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CART) {
  //     let newProd: UserCartDetailDto = new UserCartDetailDto()
  //     newProd.titleId = Number.parseInt(this.productDetails.titleId);
  //     newProd.variantId = this.currVariantId
  //     newProd.numCopies = this.productQuantity
  //     if (window.localStorage.getItem('currentUser') !== null) {
  //       newProd.isFromCart = false
  //       this.addToCartWishlistService.AddToCartWishlist(this.currUserEmail, false, newProd, (resp) => {
  //         if (resp != null) {
  //           if (resp.data == 1) {
  //             this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
  //             });
  //           } else if (resp.data == 2) {
  //             this.cartCountService.cartBehaviour.next(this.cartCount + 1)
  //             this.cartDialog.open(CartComponent, {}, null).subscribe(response => {
  //             });
  //           }
  //         } else {
  //           // errorLabel.className = "errrorLable show";
  //           // errorLabel.innerHTML = resp.msgList[0];
  //         }
  //       })
  //     } else {
  //       if (!localStorage.getItem('localCart')) {
  //         let localCart: Array<UserCartDetailDto> = new Array()
  //         localCart.push(newProd)
  //         localStorage.setItem('localCart', JSON.stringify(localCart))
  //       } else {
  //         let localCart: Array<UserCartDetailDto> = JSON.parse(localStorage.getItem('localCart'))
  //         let flag: boolean = true
  //         let finalFlag: boolean = false
  //         for (var index = 0; index < localCart.length; index++) {
  //           var element = localCart[index];
  //           if (newProd.titleId == element.titleId) {
  //             flag = false
  //             if (element.variantId) {
  //               if (!finalFlag)
  //                 flag = true
  //               if (element.variantId == newProd.variantId) {
  //                 flag = false
  //                 finalFlag = true
  //               }
  //             }
  //           }
  //         }
  //         if (flag) {
  //           localCart.push(newProd)
  //           localStorage.setItem('localCart', JSON.stringify(localCart))
  //           this.cartCountService.cartBehaviour.next(this.cartCount + 1)
  //           this.cartDialog.open(CartComponent, {}, null).subscribe(response => {
  //           });
  //         } else {
  //           this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added')
  //         }
  //       }
  //     }
  //   } else if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CHECKOUT) {
  //     let newProd: UserCartDetailDto = new UserCartDetailDto()
  //     newProd.titleId = Number.parseInt(this.productDetails.titleId);
  //     newProd.variantId = this.currVariantId
  //     newProd.numCopies = this.productQuantity
  //     if (window.localStorage.getItem('currentUser') !== null) {
  //       newProd.isFromCart = false
  //       this.addToCartWishlistService.AddToCartWishlist(this.currUserEmail, false, newProd, (resp) => {
  //         if (resp != null) {
  //           if (resp.data == 2) {
  //             this.cartCountService.cartBehaviour.next(this.cartCount + 1)
  //           }
  //         } else {
  //           // errorLabel.className = "errrorLable show";
  //           // errorLabel.innerHTML = resp.msgList[0];
  //         }
  //       })
  //     } else {
  //       if (!localStorage.getItem('localCart')) {
  //         let localCart: Array<UserCartDetailDto> = new Array()
  //         localCart.push(newProd)
  //         localStorage.setItem('localCart', JSON.stringify(localCart))
  //       } else {
  //         let localCart: Array<UserCartDetailDto> = JSON.parse(localStorage.getItem('localCart'))
  //         let flag: boolean = true
  //         let finalFlag: boolean = false
  //         for (var index = 0; index < localCart.length; index++) {
  //           var element = localCart[index];
  //           if (newProd.titleId == element.titleId) {
  //             flag = false
  //             if (element.variantId) {
  //               if (!finalFlag)
  //                 flag = true
  //               if (element.variantId == newProd.variantId) {
  //                 flag = false
  //                 finalFlag = true
  //               }
  //             }
  //           }
  //         }
  //         if (flag) {
  //           localCart.push(newProd)
  //           localStorage.setItem('localCart', JSON.stringify(localCart))
  //           this.cartCountService.cartBehaviour.next(this.cartCount + 1)
  //         } else {
  //         }
  //       }
  //     }
  //     this.router.navigate(['checkout']);
  //   }
  // }

  buyProduct() {
    this.isBuy = true;
    this.addToCartProduct();
  }

  addToCartProduct() {
    if (isPlatformBrowser(this.platformId)) {
      let localCart: CartModel;
      let newProd: CartProductModel = new CartProductModel();
      newProd.titleId = Number.parseInt(this.productDetails.titleId);
      newProd.variantId = this.currVariantId;
      newProd.numCopies = this.productQuantity;
      if (localStorage.getItem('localCart')) {
        localCart = JSON.parse(localStorage.getItem('localCart'));
      }
      if (window.localStorage.getItem('currentUser') !== null) {
        //logged in
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
          //update localCart
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        //compute cart
        if (!this.checkProductAlreadyPresent(newProd, false)) {
          this.cartService.computeCart(newCart, (resp) => {
            if (!resp.error && resp.data) {
              this.setLocalCart(resp.data[0], false);
              this.cartCountService.updateCart(resp.data[0]);
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
              if (this.isBuy) {
                if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CART) {
                  // this.router.navigate(['/shopping-cart']);
                  // this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Item added to cart!');                  
                } else if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CHECKOUT) {
                  this.router.navigate(['checkout']);
                }
                this.isBuy = false;
              }
            } else {
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error in adding product to cart!');
            }
          });
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      } else {
        //not logged in
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart) {
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        if (!this.checkProductAlreadyPresent(newProd, false)) {
          this.setLocalCart(newCart, false);
          this.cartCountService.updateCart(newCart);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
          if (this.isBuy) {
            if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CART) {
              // this.router.navigate(['/shopping-cart']);
              // this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Item added to cart!');              
            } else if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CHECKOUT) {
              this.router.navigate(['checkout']);
            }
            this.isBuy = false;
          }
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      }
    }
  }

  checkProductAlreadyPresent(cartProduct: CartProductModel, isWishlist: boolean): boolean {
    if (!isPlatformBrowser(this.platformId)) { return }
    let localCart;
    if (isWishlist) {
      localCart = JSON.parse(localStorage.getItem('wishlist'));
    } else {
      localCart = JSON.parse(localStorage.getItem('localCart'));
    }
    let present: boolean;
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      for (let index = 0; index < localCart.cartProductModels.length; index++) {
        var element: CartProductModel = localCart.cartProductModels[index];
        if (element.titleId == cartProduct.titleId) {
          present = true;
          if (element.variantId && cartProduct.variantId) {
            present = false;
            if (element.variantId == cartProduct.variantId) {
              present = true;
              break;
            }
          } else {
            break;
          }
        }
      }
    }
    if (present) {
      return true;
    } else {
      return false;
    }
  }

  setProductVariant(event, variantTypeIdx, optionIdx, mappedUniqueIds) {
    this.selectionArray[variantTypeIdx] = optionIdx
    this.filterSelected = true
    this.selectedVariantTypeIdx = variantTypeIdx
    this.selectedOptionIdx = optionIdx
    if (!this.variantMap.has(variantTypeIdx)) {
      this.variantMap.set(variantTypeIdx, {
        index: optionIdx,
        mapped: mappedUniqueIds
      })
    } else {
      this.variantMap.set(variantTypeIdx, {
        index: optionIdx,
        mapped: mappedUniqueIds
      })
    }
    if (this.variantMap.size == this.variantTypes.length) {
      this.getVariantId(this.variantMap);
    } else {
      this.selectionArray = this.defaultSelectedArray;
    }
  }

  getVariantId(map: Map<number, { index: number, mapped: Array<number> }>) {
    let resId;
    if (map.size == 1) {
      resId = map.get(0).mapped;
    } else {
      let temp = []
      let inited = false
      map.forEach(value => {
        if (!inited) {
          inited = true
          temp = value.mapped
        } else {
          temp = this.intersectArray(temp, value.mapped);
        }
      })
      resId = temp
    }
    this.currVariantId = resId[0];
    this.selectedVariantId = resId[0];
    this.updateProductByVariantId(this.selectedVariantId);
  }

  intersectArray(arr1, arr2) {
    return arr1.filter(function (n) {
      return arr2.indexOf(n) !== -1
    })
  }

  updateProductByVariantId(id) {
    let t = this.activatedRoute.snapshot.queryParamMap.get("t").toString();
    let urlText = ""
    if (this.productDetails.productURLText) {
      urlText = this.productDetails.productURLText;
    }
    let params = {
      t: t,
      sizeRecNum: id
    }
    let qParams = Object.assign({}, params)
    this.router.navigate(['/details', urlText], { queryParams: qParams })
    this.isProNotAvailable = false;
    let aFlag = 0;
    this.productDetails.variantList.forEach(variant => {
      if (variant.id == id) {
        aFlag = 1;
        this.productName = this.productDetails.productName;
        this.imagesList = [];
        this.imageThumbnailsList = [];
        this.imagesList = variant.images;
        let i = 0;
        if (this.imagesList != undefined) {
          this.imagesList.forEach(imgSrc => {
            if (imgSrc != undefined) {
              this.imageThumbnailsList[i++] = this.imageUrl + 's/' + imgSrc;
            }
          });
          this.image = [];
          this.image = this.imageUrl + 'l/' + this.imagesList[0];
        }
        this.isoutOfStock = variant.outOfStock;
        this.giveRating = variant.productRatingAvg;
        this.mrp = variant.mrp;
        this.salePrice = variant.salePrice;
        this.discount = variant.discount;
        // this.discountPercent = variant.discountPercent;
        this.showSaving = variant.showSaving;
        this.gst = variant.gst;
        if (variant.buyEnquiryMode == Constants.BUY_MODE) {
          this.buyMode = true;
        } else if (variant.buyEnquiryMode == Constants.ENQUIRY_MODE) {
          this.enquiryMode = true;
        } else if (variant.buyEnquiryMode == Constants.BUY_AND_ENQUIRY_MODE) {
          this.buyAndEnquiryMode = true;
        }
        this.featureArray = [];
        if (variant.attributes != undefined) {
          let tempArray: Object = variant.attributes;
          Object.keys(tempArray).forEach(key => {
            let value = tempArray[key]
            let featArray: Array<{ key: string, value: string }> = []
            Object.keys(value).forEach(element => {
              featArray.push({
                key: element,
                value: value[element]
              })
            })
            this.featureArray.push({
              name: key,
              details: featArray
            })
          });
        }
      }
    })
    if (aFlag == 1) {
      this.isProNotAvailable = false;
    } else {
      this.isProNotAvailable = true;
    }
  }

  addReview() {
    if (!isPlatformBrowser(this.platformId)) { return }
    if (window.localStorage.getItem('currentUser') !== null) {
      this.addReviewDialogService.open(ProductReviewComponent, {}, null).subscribe(response => {
      });
    } else {
      this.loginDialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addReviewDialogService.open(ProductReviewComponent, {}, null).subscribe(response => {
          });
        }
      });
    }
  }

  setLocalCart(cart: CartModel, isWishlist: boolean) {
    if (!isPlatformBrowser(this.platformId)) { return }
    let newLocalCart: CartModel = new CartModel();
    if (cart && cart.cartProductModels && cart.cartProductModels.length > 0) {
      newLocalCart.cartProductModels = [];
      cart.cartProductModels.forEach((ele) => {
        if (!ele.offer) {
          let localCartEle: CartProductModel = new CartProductModel();
          if (ele.titleId)
            localCartEle.titleId = ele.titleId;
          if (ele.variantId)
            localCartEle.variantId = ele.variantId;
          if (ele.numCopies)
            localCartEle.numCopies = ele.numCopies;
          newLocalCart.cartProductModels.push(localCartEle);
        }
      });
    }
    if (!isWishlist) {
      localStorage.setItem('localCart', JSON.stringify(newLocalCart));
    } else {
      localStorage.setItem('wishlist', JSON.stringify(newLocalCart));
    }
  }

  addToCartWishList() {
    if (!isPlatformBrowser(this.platformId)) { return }
    let newProd = new CartProductModel();
    newProd.titleId = Number.parseInt(this.productDetails.titleId);
    newProd.variantId = this.currVariantId;
    newProd.numCopies = 1;
    if (window.localStorage.getItem('currentUser') !== null) {
      this.addToCartWishlistUtility(newProd, false);
    } else {
      this.loginDialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe((resp) => {
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addToCartWishlistUtility(newProd, false);
        }
      })
    }
  }

  addToCartWishlistUtility(newProd: CartProductModel, isLoginAction: boolean) {
    if (!isPlatformBrowser(this.platformId)) { return }
    let msg = 'Added to your Wishlist!';
    let errMsg = 'Error in adding product to wishlist!';
    let localWishlistCart: CartModel;
    if (localStorage.getItem('wishlist')) {
      localWishlistCart = JSON.parse(localStorage.getItem('wishlist'));
    }
    let newWishlistCart: CartModel = new CartModel();
    newWishlistCart.cartProductModels = [];
    newWishlistCart.cartProductModels.push(newProd);
    newWishlistCart.cartWishListType = Constants.CART_WISHLIST_TYPE.WISHLIST;
    if (localWishlistCart && localWishlistCart.cartProductModels && localWishlistCart.cartProductModels.length > 0) {
      localWishlistCart.cartProductModels.forEach((ele: CartProductModel) => {
        newWishlistCart.cartProductModels.push(ele);
      });
    }
    if (!this.checkProductAlreadyPresent(newProd, true)) {
      this.cartService.computeCart(newWishlistCart, (resp) => {
        if (!resp.error && resp.data && resp.data[0]) {
          this.setLocalCart(resp.data[0], true);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, errMsg);
        }
      });
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
    }
  }

  openFunFactsDetailPage() {
    this.ffRightUpdatedValue = 0//
    this.funFactsDetailsPage = true;
  }

  hideFunFactsDetailPage() {
    this.ffRightUpdatedValue = 0//    
    this.funFactsDetailsPage = false
  }

  showInitalFunFact() {
    this.ffRightUpdatedValue = 0//
    this._hideAllFacts = true;
  }

  setFunFacts() {
    this.funFacts.push(Constants.FUN_FACTS.FUN_FACT_1);
    this.funFacts.push(Constants.FUN_FACTS.FUN_FACT_2);
    this.funFacts.push(Constants.FUN_FACTS.FUN_FACT_3);
    this.funFacts.push(Constants.FUN_FACTS.FUN_FACT_4);
  }

  setFunFactsTimer() {
    // this.timerHelper();    
    this.ffSlideTimer = setInterval(() => {
      this.timerHelper();
    }, 3000)
  }

  timerHelper() {
    if ((this.ffRightUpdatedValue / 100) <= 0) {
      while ((this.ffRightUpdatedValue / 100) < 1) {
        this.ffRightUpdatedValue = this.ffRightUpdatedValue + 100
      }
    } else if ((this.ffRightUpdatedValue / 100) > 0) {
      while ((this.ffRightUpdatedValue / 100) > 0) {
        this.ffRightUpdatedValue = this.ffRightUpdatedValue - 100;
      }
    }
  }

  startTimer() {
    this.timerHelper();
    this.setFunFactsTimer()
  }

  stopTimer() {
    clearInterval(this.ffSlideTimer);
  }

  mouseOverFunc(event) {
    if (this.getSurveyForm) {
      this.stopTimer()
    }
  }

  mouseOutFunc(event) {
    if (this.getSurveyForm) {
      this.startTimer()
    } else {
      this.stopTimer()
    }
  }

  funFactsSlideLeft(length) {
    clearInterval(this.ffSlideTimer);
    if ((this.ffRightUpdatedValue / 100) < length - 1) {
      this.ffRightUpdatedValue = this.ffRightUpdatedValue + 100;
    }
  }

  funFactsSlideRight(length) {
    clearInterval(this.ffSlideTimer);
    if ((this.ffRightUpdatedValue / 100) > 0)
      this.ffRightUpdatedValue = this.ffRightUpdatedValue - 100;
  }

  minimizeFunFact() {
    this.isFunFactMinimized = true
    this.ffRightUpdatedValue = 0 //
    this._hideAllFacts = this._hideAllFacts ? false : true
  }

  checkSurveyFormVisibility() {
    this.customerSurveyService.getSurveyForm(resp => {
      this.ffRightUpdatedValue = 0 //
      if (!resp.error) {
        this.getSurveyForm = true
        if (this.FirstTime) {
          this.startTimer()
          this.FirstTime = false
        }
        if (resp.data != undefined && resp.data != null && resp.data.formId != undefined && resp.data.formId != null)
          this.formId = resp.data.formId
        this.surveyFormData.push({
          formId: this.formId
        })
      } else {
        clearInterval(this.ffSlideTimer);
        this.getSurveyForm = false
      }
    })
  }

  openSurvey() {
    this.customerSurveyDialogService.open(CustomerSurveyComponent, {}, this.surveyFormData).subscribe((response) => {
      if (response == 'yes') {
        let answerModel = new AnswerModel()
        answerModel.formId = this.formId
        let isSurveyFormFilled = false
        this.cartCountService.surveyFormFillObservable.subscribe((flag) => {
          isSurveyFormFilled = flag
        })
        if (!isSurveyFormFilled) {
          this.customerSurveyService.submitQuestionsForSurvey(answerModel, (resp) => {
            if (!resp.error) {
            }
          })
        } else {
          this.stopTimer();
        }
        this.checkSurveyFormVisibility();
      }
    });
  }

  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
